import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Vue from 'vue'

export default function usePOReceivesWarehouseList() {
  const toast = useToast()
  const refPurchaseOrderReceiveWarehouseTable = ref(null)
  const tableColumns = [
    { key: 'No', label: 'No', sortable: false },
    { key: 'Code', label: 'Code', sortable: false },
    { key: 'Description', label: 'Description', sortable: false },
    { key: 'CountSupplies', label: 'Count Supplies', sortable: false },
    { key: 'TotalSupplies', label: 'Total Supplies', sortable: false },
    { key: 'CountFGS', label: 'Count FGS', sortable: false },
    { key: 'TotalFGS', label: 'Total FGS', sortable: false },
    { key: 'CountAssets', label: 'Count Asset', sortable: false },
    { key: 'TotalAssets', label: 'Total Asset', sortable: false },
    { key: 'CountAll', label: 'Count Total', sortable: false },
    { key: 'TotalAll', label: 'Total All', sortable: false },
    { key: 'Action', label: 'Action', sortable: false },
  ]
  const perPage = ref(10)
  const totalPurchaseOrderSummaryReceives = ref(0)
  const currentPage = ref(1)
  const totalCount = ref(0)
  const totalSum = ref(0)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const startDateFilter = ref('')
  const endDateFilter = ref('')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPurchaseOrderReceiveWarehouseTable.value ? refPurchaseOrderReceiveWarehouseTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPurchaseOrderSummaryReceives.value,
    }
  })

  const refetchData = () => {
    refPurchaseOrderReceiveWarehouseTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc, startDateFilter, endDateFilter], () => {
    refetchData()
  })

  const fetchPurchaseOrderSummaryReceives = (ctx, callback) => {
    const userToken = Vue.$cookies.get('userToken')
    const to = perPage.value * (currentPage.value - 1)
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_SUMMARY}?limit=${perPage.value}&offset=${to}&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}`, { headers })
      .then(response => {
        totalPurchaseOrderSummaryReceives.value = response.data.data_count
        totalCount.value = response.data.data.totalCountAll
        totalSum.value = response.data.data.totalAll
        callback(response.data.data.dataTable)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    startDateFilter,
    endDateFilter,
    fetchPurchaseOrderSummaryReceives,
    tableColumns,
    perPage,
    currentPage,
    totalPurchaseOrderSummaryReceives,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalCount,
    totalSum,
    isSortDirDesc,
    refPurchaseOrderReceiveWarehouseTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    roleFilter,
    statusFilter,
  }
}
